export default {
  init() {

    document.addEventListener('click', function (event) {
      if (event.target.classList.contains('fa-copy') || event.target.classList.contains('copy-button')) {
        var input = event.target.parentNode.previousElementSibling;
        if (input) {
          input.select();
          document.execCommand('copy');

        }
      }
    });


    document.addEventListener('click', function (event) {
      var content = document.querySelector('.copy-link-field');


      if (event.target.classList.contains('share-comments--link') || event.target.classList.contains('fa-link')) {

        if (content.classList.contains('show')) {
          // Hide the content
          content.style.height = content.scrollHeight + 'px';
          setTimeout(function () {
            content.style.opacity = '0';
            content.style.height = '0';
          }, 10);
        } else {
          // Show the content
          content.style.opacity = '0';
          content.style.height = 'auto';
          setTimeout(function () {
            content.style.opacity = '1';
            content.style.height = content.scrollHeight + 'px';
          }, 10);
        }

        content.classList.toggle('show');

      }
    });


    // 1. Check if there are more than 2 <p> elements inside the class
    var podcastContent = document.querySelector('.podcast_episode_transcript-content');
    if (podcastContent) {
      var paragraphs = podcastContent.getElementsByTagName('p');

      if (paragraphs.length > 2) {
        // 2. Hide the rest of the elements after the second <p>
        for (var i = 2; i < paragraphs.length; i++) {
          paragraphs[i].style.maxHeight = '0';
          paragraphs[i].style.overflow = 'hidden';
          paragraphs[i].style.transition = 'max-height 0.5s ease';
        }

        // 3. Add a button to toggle visibility
        var button2 = document.createElement('button');
        button2.classList.add('btn-transcript-view');
        button2.textContent = 'Read more';

        var icon2 = document.createElement('i');
        icon2.classList.add('fa-solid', 'fa-angle-down');
        button2.appendChild(icon2);


        podcastContent.appendChild(button2);

        // Toggle visibility when the button is clicked
        button2.addEventListener('click', function () {
          for (var i = 2; i < paragraphs.length; i++) {
            if (paragraphs[i].style.maxHeight === '0px') {
              paragraphs[i].style.maxHeight = paragraphs[i].scrollHeight + 'px';
            } else {
              paragraphs[i].style.maxHeight = '0';
            }
          }

          if (button2.textContent === 'Read more') {
            button2.textContent = 'Hide';
            var icon3 = document.createElement('i');
            icon3.classList.add('fa-solid', 'fa-angle-up');
            button2.appendChild(icon3);
          } else {
            button2.textContent = 'Read more';
            var icon4 = document.createElement('i');
            icon4.classList.add('fa-solid', 'fa-angle-down');
            button2.appendChild(icon4);
          }

        });
      }
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
