// import external dependencies
import 'jquery';
import Splide from '@splidejs/splide';

// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import episode from './routes/episode';



/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // Episode Single
  episode,
});

// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the Facebook and Twitter icons
import { faFacebookF, faTwitter, faInstagram, faLinkedin, faYoutube, faSpotify, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faAngleRight, faAngleLeft, faAngleUp, faAngleDown, faMagnifyingGlass, faList, faTimes, faUser, faSquarePlus, faPlay, faMoon, faSun } from '@fortawesome/pro-solid-svg-icons';
import { faLocationDot, faLink, faCopy, faMobile, faEnvelope, faPhone, faCartShopping, faPodcast, faArrowRight, faComment } from '@fortawesome/pro-light-svg-icons';


// add the imported icons to the library
library.add(faFacebookF, faTwitter, faLink, faCopy, faInstagram, faYoutube, faLocationDot, faUser, faList, faTimes, faSquarePlus, faPlay, faMoon, faSun, faLinkedin, faComment, faCartShopping, faMagnifyingGlass, faMobile, faPhone, faEnvelope, faAngleRight, faAngleLeft, faAngleUp, faAngleDown, faSpotify, faGoogle, faPodcast, faArrowRight);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

// Load Events
jQuery(document).ready(() => routes.loadEvents());




document.addEventListener('DOMContentLoaded', function () {

  const checkbox = document.querySelector('.light-mode__checkbox');
  const body = document.body;

  checkbox.addEventListener('click', () => {
    console.log('Clicked');
    body.classList.toggle('ffsn-light-mode');
  });

  var $dropdowns = getAll('.navbar-item.has-dropdown:not(.is-hoverable)');

  if ($dropdowns.length > 0) {
    $dropdowns.forEach(function ($el) {
      $el.addEventListener('click', function (e) {
        e.stopPropagation();
        $el.classList.toggle('is-active');
      });
    });

    document.addEventListener('click', function (event) {
      event.closeDropdowns();
    });
  }

  function closeDropdowns() {
    $dropdowns.forEach(function ($el) {
      $el.classList.remove('is-active');
    });
  }

  // Close dropdowns if ESC pressed
  document.addEventListener('keydown', function (event) {
    var e = event || window.event;
    if (e.keyCode === 27) {
      closeDropdowns();
    }
  });

  // Toggles

  var $burgers = getAll('.burger');

  if ($burgers.length > 0) {
    $burgers.forEach(function ($el) {
      $el.addEventListener('click', function () {
        var target = $el.dataset.target;
        var $target = document.getElementById(target);
        $el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
      });
    });
  }

  // Functions

  function getAll(selector) {
    return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
  }
});

//Slider

if (document.querySelectorAll('.home-splide')) {
  var splide = new Splide('.home-splide',
    {
      type: 'loop',
      pagination: false,
      height: '650px',
      perPage: 1,
      arrows: false,
      breakpoints: {
        640: {
          height: '400px',
          perPage: 1,
          arrows: false,
        },
      },
    }
  );
  splide.mount();
}

if (document.querySelectorAll('.sliderBrand')) {
  var sliderBrand = document.querySelectorAll('.sliderBrand');

  if (sliderBrand.length > 0) {
    var splide2 = new Splide('.sliderBrand',
      {
        type: 'loop',
        pagination: false,
        height: '240px',
        autoplay: true,
        perPage: 4,
        arrows: false,
        breakpoints: {
          640: {
            height: '240px',
            perPage: 1,
            arrows: false,
          },
        },
      }
    );
    splide2.mount();
  }
}


if (document.querySelectorAll('.sliderBrand')) {
  const sliders = document.querySelectorAll('.sliderBrand');

  sliders.forEach(slider => {
    const splide = new Splide(slider, {
      type: 'loop',
      pagination: false,
      perPage: 4,
      gap: 1,
      height: '650px',
      autoplay: true,
      breakpoints: {
        640: {
          perPage: 2,
          height: '240px',
          gap: 1,
        },
      },
    });
    splide.mount();
  });
}

splide.on('visible', function (slide) {

  const titles = document.querySelectorAll('.splide__slide .splide__slide_title_h1');
  const subtitles = document.querySelectorAll('.splide__slide .splide__slide_title_p');
  const buttons = document.querySelectorAll('.splide__slide .wp-block-button');

  const title = document.querySelector('.slide-' + slide.index + ' .splide__slide_title_h1');
  const subtitle = document.querySelector('.slide-' + slide.index + ' .splide__slide_title_p');
  const button = document.querySelector('.slide-' + slide.index + ' .wp-block-button');


  titles.forEach((t) => {
    t.classList.remove('animate__animated', 'animate__fadeInDownBig', 'animate__slower');
  });

  subtitles.forEach((s) => {
    s.classList.remove('animate__animated', 'animate__fadeInDownBig', 'animate__slow');
  });

  buttons.forEach((b) => {
    b.classList.remove('animate__animated', 'animate__fadeInUpBig', 'animate__slow');
  });


  title.classList.add('animate__animated', 'animate__fadeInDownBig', 'animate__slower');
  subtitle.classList.add('animate__animated', 'animate__fadeInDownBig', 'animate__slow');
  button.classList.add('animate__animated', 'animate__fadeInUpBig', 'animate__slow');

});

$('.wc-block-grid__product').mouseover(function () {
  $(this).children('.wc-block-grid__product-add-to-cart').toggleClass('show-addtocart');
});

$('.wc-block-grid__product').mouseout(function () {
  $(this).children('.wc-block-grid__product-add-to-cart').toggleClass('show-addtocart');
});


const tabs = document.querySelectorAll('.tabs a');
const tabContents = document.querySelectorAll('.tab-content');

tabs.forEach(tab => {
  tab.addEventListener('click', () => {
    // Remove the 'is-active' class from all tabs
    tabs.forEach(tab => tab.classList.remove('is-active'));

    // Add the 'is-active' class to the clicked tab
    tab.classList.add('is-active');

    // Hide all tab contents
    tabContents.forEach(content => content.style.display = 'none');

    // Show the corresponding tab content
    const tabContent = document.querySelector(tab.getAttribute('href'));
    tabContent.style.display = 'block';
  });
});


document.addEventListener('click', function (event) {
  if (event.target.classList.contains('copy-button')) {
    console.log('Clicked');
    var input = event.target.parentNode.previousElementSibling;
    if (input) {
      input.select();
      document.execCommand('copy');
      alert('Copied: ' + input.value);
    }
  }
});

